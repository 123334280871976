//@import "../../../assets/scss/variables";
//@import "../../../assets/scss/fonts";
//@import "../../../assets/scss/mixins";
//@import "../../../assets/scss/function";

.ContentText {
  padding: 0 23px;
  margin-bottom: 101px;

  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      background-color: #0505BE;
      border: none;
      border-radius: 24px;
      box-shadow: 0 3px 6px #122F421A;
      color: white;
      cursor: pointer;
      font-family: Grotesk, sans-serif;
      font-size: 20px;
      font-weight: 700;
      height: 48px;
      margin: 20px 0 20px 0;
      outline: transparent;
      padding: 10px 30px;
      width: 100%;
    }
  }

  .url-container {
    p {
      font-size: 15px;
      line-height: 15px;
      margin-bottom: 50px;
    }

    a {
      margin-left: 10px;
      text-decoration: none;
    }
  }

  p {
    color: black;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.01px;
    line-height: 29px;
    margin-bottom: 15px;
    width: 100%;
  }

  .anchor-button {
    button {
      background-color: unset;
      background-image: url("../../../../../src/assets/img/anchor-icon.png");
      background-size: cover;
      border: none;
      cursor: pointer;
      height: 30px;
      margin-bottom: 30px;
      margin-top: 10px;
      outline: none;
      width: 99px;
    }
  }

  .references-link {
    display: block;
    font-family: Grotesk, sans-serif;
    margin: 10px 0 20px 0;

    p {
      font-weight: 700;
    }

    div {
      margin-top: 10px;

      a {
        color: #0505BE;
        outline: none;
      }
    }
  }

  //@include breakpoint(desktop-small) {
  //  margin-bottom: 101px;
  //  padding: 0;
  //
  //  p {
  //    color: black;
  //    font-size: 20px;
  //    font-weight: 400;
  //    letter-spacing: 0.01px;
  //    line-height: 29px;
  //    margin-bottom: 15px;
  //    width: 858px;
  //  }
  //}
}

.CheckboxWithDescription {
  display: flex;
  align-items: center;

  label {
    min-width: 200px;
    max-width: 200px;
  }

  input {
    min-width: 200px;
    max-width: 200px;
  }

  p {
    width: 100%;
  }

  &.selected {
    background-color: black;
    color: white;
  }
}
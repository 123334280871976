@import "../../../assets/scss/fonts";
@import "../../../assets/scss/variables";

.GoogleSignIn {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  height: 35px;
  justify-content: center;
  margin: 10px;
  padding: 4px;

  button {
    box-shadow: none !important;
    color: $mainColor !important;
    cursor: pointer;
    font-size: 14px !important;
    letter-spacing: 0;
    text-transform: uppercase;
    width: 250px;

    div {
      align-items: center !important;
      background-color: transparent !important;
      display: flex;
      margin-right: 0 !important;
    }

    &:hover {
      background-color: rgb(235, 234, 234) !important;
    }
  }

  &:hover {
    background-color: rgb(235, 234, 234);
  }
}

@import "../../../../assets/scss/variables.scss";
@import "../../../../assets/scss/mixins.scss";

.container-reports {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoint(xxl) {
    flex-direction: row;
  }
}


.ContentTagList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  left: -5px;
  margin-bottom: 109px;
  margin-top: 28px;
  max-width: 886px;
  padding-left: 25px;
  position: relative;
  top: 1px;

  .Tag {
    align-items: center;
    background-color: #CFEC59;
    border: none;
    color: black;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    padding: 0;
    width: auto;
    font-size: 16px;
    height: 28px;
    justify-content: center;
    min-width: 131px;
    outline: none;
    border-radius: 30px;
  }
}

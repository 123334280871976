@import "../../assets/scss/variables.scss";

.Login {
  align-items: center;
  background-color: $background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .logo{
    margin-bottom: 40px;
    width: 150px;
  }

  .container{
    box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 520px;
    min-height: 400px;
    padding: 60px;
    width: 100%;
  }

  .social-buttons{
    display: flex;
    justify-content: space-around;

    .btn{
      font-size: 14px;
      letter-spacing: 0;
      width: 250px;

      svg{
        width: 17px;
      }
    }
  }

  .innerText{
    font-size: 17px;
    text-align: center;
  }

  .signup{
    align-items: center;
    display: flex;
    font-size: 15px;
    justify-content: flex-end;

    .cta{
      color: rgb(18, 144, 117);
      padding-left: 5px;
      text-decoration: none;
    }
  }

  .inputField, .btn{
    font-size: $fontInput;
    letter-spacing: 1px;
    margin: 10px 0;
    width: 100%;
  }

  .forgotPassword{
    color: #616060;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-left: 5px;
    text-decoration: none;
  }
}

$mainColor: #000;
$error: #FF0000;
$background: #e9e9e9;

$breakpoints: (
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xll": 1400px,
  "xxl": 1600px,
  "xxxl": 1800px
);

$fontInput: 16px;
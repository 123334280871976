@font-face {
    font-family: 'WorkSans-Regular';
    src: url("../fonts/WorkSans-Regular.ttf")
}

@font-face {
    font-family: 'WorkSans-Medium';
    src: url("../fonts/WorkSans-Medium.ttf")
}

@font-face {
    font-family: 'WorkSans-Bold';
    src: url("../fonts/WorkSans-Bold.ttf")
}

@font-face {
    font-family: 'WorkSans-SemiBold';
    src: url("../fonts/WorkSans-SemiBold.ttf")
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url("../fonts/Roboto-Regular.ttf")
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url("../fonts/Roboto-Bold.ttf")
}

@import "../../assets/scss/variables.scss";

.SignUp {
  align-items: center;
  background-color: $background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .logo {
    margin-bottom: 40px;
    width: 110px;
  }

  .container {
    align-items: center;
    box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    min-height: 550px;
    padding: 60px;
    width: 100%;
  }

  .rounded {
    align-items: center;
    background-color: #3f51b5;
    border-radius: 64px;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 60px;
  }

  .innerText {
    font-size: 20px;
    text-align: center;
  }

  .inputField,
  .btn {
    font-size: $fontInput;
    letter-spacing: 1px;
    margin: 10px 0;
    width: 100%;
  }

  .login {
    align-items: center;
    display: flex;
    font-size: 15px;
    justify-content: flex-end;

    .cta{
      color: rgb(18, 144, 117);
      padding-left: 5px;
      text-decoration: none;
    }
  }

  .tip{
    color: #616060;
    font-size: 14px;
    margin-bottom: 20px;
    padding-left: 5px;
  }
}

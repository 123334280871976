
.ContentAudio {
  div {
    align-items: flex-end;
    background-color: black;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 360px !important;
    width: 640px;

    audio {
      height: 30px;
      margin-bottom: 10px;
      outline: none;

      &::-webkit-media-controls-enclosure {
        border-radius: 5px;
        width: 90%;
      }

      &::-webkit-media-controls-panel {
        background-color: white;
      }

      &::-webkit-media-controls-mute-button {
        background-color: white;
      }

      &::-webkit-media-controls-play-button {
        background-color: white;
      }

      &::-webkit-media-controls-time-remaining-display {
        display: none;
      }
    }
  }
}

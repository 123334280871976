@import "../assets/scss/variables.scss";

.errorMessage{
  display: none;
}

.errorMessageActive{
  color: $error;
  display: block;
  font-size: 13px;
}

svg{
  cursor: pointer;
}

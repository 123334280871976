.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  margin-top: 30px;  

  textarea {
    height: 80px;
    resize: none;
    width: 300px;
  }

  label {
    margin: 5px 10px 5px 0;
  }

  input {
    background-color: #fff;
    border: 1px solid #ddd;
    margin: 5px 10px 5px 0;
    padding: 10px;
    vertical-align: middle;
  }

  .button-section {
    margin: 3%;
  }

  .button {
    align-items: center;
    background-color: dodgerblue;
    border-radius: 5px;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.9375rem;
    font-weight: 500;
    margin-left: 20px;
    padding: 10px 20px;
    text-transform: uppercase;

    svg {
      margin-left: -4px;
      margin-right: 8px;
    }
  }
  
  .button:hover {
    background-color: royalblue;
  }
  
  .remove {
    background-color: rgb(192, 53, 53);
  }
  .remove:hover {
    background-color: rgb(187, 43, 43);
  }
}

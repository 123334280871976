.AnswerTableRowScenario {
  margin-right: 100px;
  margin-top: 20px;
  padding: 20px;
  width: 100%;

  & > div {
    background-color: black;
    padding: 30px;

    & > div {
      & > div {
        display: flex;
        margin-top: 20px;

        &:nth-child(2) {
          align-items: flex-end;
        }

        & > div {
          align-items: center;
          border: 1px solid #de1a1a;
          color: white;
          display: flex;
          justify-content: center;
          padding: 20px;
          width: 100px;
        }

        & > span {
          color: white;
          margin-left: 5px;
        }
      }
    }
  }
}

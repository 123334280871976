@import "../../assets/scss/fonts.scss";

.Navbar{
  background-color: white;
  height: 90px;
  justify-content: center;
  width: 100%;
  z-index: 4;

  .navContent{
      align-items: center;
      color:black;
      cursor: pointer;
      display: flex;
      height: 100%;
      text-decoration: none;
      width: 98%;

      img{
          width: 90px;
      }

      p{
          font-family: 'Worksans-Regular';
      }

      span{
          font-family: 'Worksans-Bold';
      }
  }
}

.Projects {
  display: flex;
  flex-direction: column;
}

.green {
  background-color: greenyellow;
  font-weight: bold;
  padding: 5px;
}

.red {
  background-color: rgb(255, 0, 0);
  color: white;
  font-weight: bold;
  padding: 5px;
}

.title-checks {
  display: flex;

  p {
    &:first-child {
      min-width: 200px;
    }

    &:nth-child(2) {
      min-width: 200px;
      text-align: center;
    }

    &:last-child {
      width: 100%;
    }
  }
}

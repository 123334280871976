
.UserContent {
  max-width: 886px;
  padding: 88px 58px 318px 0;

  .ContentText {
    margin-top: 33px;
  }

  .title-content {
    color: black;
    padding: 0 23px;

    .info-user-content {
      align-items: center;
      display: flex;
      margin-bottom: 21px;
      margin-top: 40px;

      img {
        border-radius: 50%;
        height: 28px;
        text-align: center;
        width: 30px;
      }

      p {
        color: black;
        display: flex;
        font-family: Roboto, sans-serif;
        font-size: 13px;
        font-weight: 100;
        margin: 0;
        opacity: 1;
        padding: 0 0 0 11px;

        &:nth-of-type(2) {
          font-size: 11px;
        }
      }
    }

    h1 {
      font-size: 36px;
      font-weight: 600;
      line-height: 51px;
      text-align: left;
    }

    p {
      color: #070706;
      display: flex;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.02px;
      margin-bottom: 65px;
      opacity: 0.6;
      padding-top: 20px;
    }
  }
}
